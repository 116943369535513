import { useCallback, useEffect, useState } from "react";
import {
  toggleDisplayMobileNavbar,
  useAppDispatch,
  useAppSelector,
} from '@longjourney/store';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { menu } from './menu';

export const Header = () => {
  const [isMobile, setMobile] = useState(window.innerWidth <= 767);

  const router = useRouter();
  const dispatch = useAppDispatch();
  const pathname = router.asPath;

  const { displayMobileNavbar } = useAppSelector((state) => state.layout);

  const handleWindowResize = useCallback(() => {
    if (window.innerWidth <= 767) {
      setMobile(true);
    } else if (isMobile) {
      setMobile(false);
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const isOnPath = (path: string) => {
    return pathname === path ? 'active' : undefined;
  };

  const handleToggle = () => {
    dispatch(toggleDisplayMobileNavbar());
  };

  return (
    <header className="header-area">
      <div className="container">
        <div className="gx-row d-flex align-items-center justify-content-between">
          <Link href="/" className="logo">
            <img src={`/assets/logo/${isMobile ? "logo-sm.png" : "logo.png"}`} alt="Logo" />
          </Link>
          <nav
            className={classNames('navbar', { active: displayMobileNavbar })}
          >
            <ul className="menu">
              {menu.map((entry, i) => (
                <li className={isOnPath(entry.path)} key={i}>
                  <Link href={entry.path} onClick={handleToggle}>
                    {entry.label}
                  </Link>
                </li>
              ))}
            </ul>
            {/*<a*/}
            {/*  className="theme-btn"*/}
            {/*  href="https://calendly.com/grishavasilyan"*/}
            {/*  target="_blank"*/}
            {/*  rel="noreferrer noopener"*/}
            {/*>*/}
            {/*  <span role="img" aria-label="coffee">*/}
            {/*    ☕*/}
            {/*  </span>{' '}*/}
            {/*  Chat with Grisha*/}
            {/*</a>*/}
          </nav>

          {/*<ThemeConfigurator />*/}
          <div
            className={classNames('show-menu', { active: displayMobileNavbar })}
            onClick={handleToggle}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    </header>
  );
};
