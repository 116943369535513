export const menu = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Bio',
    path: '/bio',
  },
  {
    label: 'Portfolio',
    path: '/portfolio',
  },
  {
    label: 'Blog',
    path: '/posts',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];
